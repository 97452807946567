import { RegisterRequest } from "src/types/levee";
import { FileWithPath } from "react-dropzone";
export type File = FileWithPath;

const axios = require("axios").default;

const snugAuthBaseURL = process.env.REACT_APP_LEVEE_API_URL;

export interface ContextValues {
  error: string;
  initialLoading: boolean;
  savingNewValues: boolean;
  deletingEntity?: boolean;
}

export enum GenericPath {
  LOGIN = "/login/",
  LOGIN_REFRESH = "/login/refresh/",
  LOGIN_VERIFY = "/login/verify/",
  REGISTER = "/register/",
  USER = "/user/",
  CREATE_ACCOUNT_GROUP = "/create_account_group/",
  ADD_USER_TO_ACCOUNT_GROUP = "/add_user_to_account_group/",
  RESET_PASSWORD_VALIDATE = "/api/password_reset/validate_token/",
  RESET_PASSWORD_CONFIRM = "/api/password_reset/confirm/",
  VECTOR_STORES_ACCOUNT_ID = "/vector-stores/{ID}/",
  VECTOR_STORES_CREATE = "/vector-stores/",
  VECTOR_STORES_PK = "/vector-stores/{ID}/",
  COMPARISON_TEXTS = "/comparison-texts/",
  COMPARISON_TEXTS_PK = "/comparison-texts/{ID}/",
  COMPARISON_RUNS = "/comparison-runs/",
  COMPARISON_RUNS_PK = "/comparison-runs/{ID}/",
  VECTOR_STORES_VECTORE_STORE_ID_COMPARISON_RUNS = "/vector-stores/{ID}/comparison-runs",
  COMPARISON_RUNS_ACCOUNT_ID = "/comparison-runs/{ID}",
  COMPARISON_RESULT_VECTOR_DETAIL = "/comparison-results/vector/{ID}/",
  COMPARISON_RESULTS_PK = "/comparison-results/{ID}/",
  COMPARISON_RUNS_COMPARISON_RUN_ID_COMPARISON_RESULTS = "/comparison-runs/{ID}/comparison-results/",
  COMPARISON_RESULTS_ACCOUNT_ID = "/comparison-results/{ID}/",
  SWAGGER = "/swagger/",
  VECTORIZE_FILE = "/vectorize-file/",
  PROMPT_COMPARISON_RUNS = "/prompt_comparison_runs/",
  PROMPT_COMPARISON_RUNS_PK = "/prompt_comparison_runs/{ID}/",
  PROMPT_COMPARISON_RUNS_ACCOUNT_ID = "/prompt_comparison_runs/{ID}/",
  PROMPT_COMPARISON_RESULTS = "/prompt_comparison_results/",
  ADD_ORGANIZATION_GROUP_TO_AUTH_GROUP = "/add_organization_group_to_auth_group/{ID}/",
}

export const leveeApiInstance = axios.create({
  baseURL: snugAuthBaseURL,
});

//dumb workaround
export const noAuthleveeApiInstance = axios.create({
  baseURL: snugAuthBaseURL,
});

export const getUser = async (token: string) => {
  const config = {
    headers: { Authorization: "Bearer " + token },
  };
  const response = await noAuthleveeApiInstance.get(GenericPath.USER, config);
  return response.data;
};

export const register = async (registerRequest: RegisterRequest) => {
  const response = await leveeApiInstance.post(
    GenericPath.REGISTER,
    registerRequest
  );
  return response;
};

export const getToken = async (email: string, password: string) => {
  const response = await leveeApiInstance.post(GenericPath.LOGIN, {
    username: email.toLowerCase(),
    password: password,
  });
  return response;
};

export const createAccountGroup = async () => {
  const response = await leveeApiInstance.post(
    GenericPath.CREATE_ACCOUNT_GROUP
  );
  return response;
};

// export const add_user_to_account_group

export const verifyToken = async (
  token: string,
  controller: AbortController
) => {
  const response = await leveeApiInstance.post(
    GenericPath.LOGIN_VERIFY,
    { token: token },
    { signal: controller.signal }
  );
  return response;
};

export const refreshToken = async (refresh: string) => {
  const response = await leveeApiInstance.post(GenericPath.LOGIN_REFRESH, {
    refresh: refresh,
  });
  return response;
};

export const resetPassWordTokenAndEmail = async (email: string) => {
  const response = await leveeApiInstance.post("password_reset/", {
    email: email.toLowerCase(),
    redirect_app_domain: process.env.REACT_APP_RESET_PW_REDIRECT_APP_DOMAIN,
  });
  return response;
};

export const resetPassword = async (token: string, newPassword: string) => {
  const response = await leveeApiInstance.post("password_reset/confirm/", {
    token: token,
    password: newPassword,
  });
  return response;
};

/// GENERIC FUNCTIONS
export const genericPutData = async <T>(data: T, path: string): Promise<T> => {
  if (data) {
    const response = await leveeApiInstance.put(path, data);
    return response.data.data as T;
  }
  return Promise.reject();
};

export const genericPostData = async <T>(data: T, path: string): Promise<T> => {
  if (data) {
    const response = await leveeApiInstance.post(path, data);
    return response.data as T;
  }
  return Promise.reject();
};

export const genericGetData = async <T>(
  path: string,
  params?: any
): Promise<T> => {
  const response = await leveeApiInstance.get(path, {
    params: params,
  });
  return response.data as T;
};

export const genericGetDataById = async <T>(
  path: string,
  pathParams: { id?: string | number },
  paginationParams?: { page: number; page_size: number } | null,
  queryParams?: any
): Promise<T> => {
  if (!pathParams.id) {
    return Promise.reject();
  }
  const response = await leveeApiInstance.get(
    path.replace("{ID}", pathParams.id.toString()),
    {
      params: { ...paginationParams, ...queryParams },
    }
  );
  return response.data as T;
};

export const postWithId = async <T>(
  data: T,
  path: string,
  pathParams: { id: string | number }
): Promise<T> => {
  if (data) {
    const response = await leveeApiInstance.post(
      path.replace("{ID}", pathParams.id.toString()),
      data
    );
    return response.data as T;
  }
  return Promise.reject();
};

export const vecetorizeFile = async (file: FileWithPath): Promise<any> => {
  const formData = new FormData();
  formData.append("file", file);
  return await leveeApiInstance.post(GenericPath.VECTORIZE_FILE, formData);
};

// DELETE DATA ///
export const deleteById = async <T>(
  id: string,
  type: string,
  path: string
): Promise<T> => {
  if (id) {
    const response = await leveeApiInstance.delete(path, {
      params: { [type]: id },
    });
    return response.data as T;
  }
  return Promise.reject();
};

//request add auth token
leveeApiInstance.interceptors.request.use((config: any) => {
  if (sessionStorage.getItem("accessToken")) {
    config.headers.Authorization =
      "Bearer " + sessionStorage.getItem("accessToken");
  }
  return config;
});
