import { User } from "src/types/levee";
import { getToken, getUser, register } from "../levee";

const STORAGE_KEY: string = "users";

// NOTE: We use sessionStorage since memory storage is lost after page reload.
//  This should be replaced with a server call that returns DB persisted data.

const getPersistedUsers = (): User[] => {
  try {
    const data = sessionStorage.getItem(STORAGE_KEY);

    if (!data) {
      return [];
    }

    return JSON.parse(data) as User[];
  } catch (err) {
    console.error(err);
    return [];
  }
};

const persistUser = (user: User): void => {
  try {
    const users = getPersistedUsers();
    const data = JSON.stringify([...users, user]);
    sessionStorage.setItem(STORAGE_KEY, data);
  } catch (err) {
    console.error(err);
  }
};

type SignInRequest = {
  email: string;
  password: string;
};

type SignInResponse = Promise<{
  accessToken: string;
}>;

type SignUpRequest = {
  email: string;
  name: string;
  password: string;
  organization?: string;
};

type SignUpResponse = Promise<{
  accessToken: string;
}>;

type MeRequest = {
  accessToken: string;
};

type MeResponse = Promise<User>;

class AuthApi {
  async signIn(request: SignInRequest): SignInResponse {
    const { email, password } = request;
    const data = await getToken(email, password);
    const accessToken = data.data.access;
    const signingInUser = await getUser(accessToken);

    return new Promise((resolve, reject) => {
      try {
        // Merge static users (data file) with persisted users (browser storage)
        const mergedUsers = [...getPersistedUsers(), signingInUser];

        // Find the user
        const user = mergedUsers.find((user) => user.username === email);

        if (!user) {
          reject(new Error("Please check your email and password"));
          return;
        }

        resolve({ accessToken });
      } catch (err) {
        console.error("[Auth Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }

  async signUp(request: SignUpRequest): SignUpResponse {
    const { email, name, password, organization } = request;

    return new Promise(async (resolve, reject) => {
      try {
        await register({
          username: email,
          first_name: name,
          password: password,
          email: email,
          last_name: "",
          organization: organization,
        });
        const data = await getToken(email, password);
        const accessToken = data.data.access;
        let signingInUser = await getUser(accessToken);

        persistUser(signingInUser);

        resolve({ accessToken });
      } catch (err) {
        console.error("[Auth Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }

  me(request: MeRequest): MeResponse {
    const { accessToken } = request;

    return new Promise(async (resolve, reject) => {
      try {
        const tokensUser = await getUser(accessToken);

        // Merge static users (data file) with persisted users (browser storage)
        const mergedUsers = [...getPersistedUsers(), tokensUser];
        const user = mergedUsers.find((user) => user.id === tokensUser.id);
        if (!user) {
          reject(new Error("Invalid authorization token"));
          return;
        }

        resolve({
          id: user.id,
          username: user.username,
          groups: user.groups,
        });
      } catch (err) {
        console.error("[Auth Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }
}

export const authApi = new AuthApi();
