import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import { Logo } from "src/components/logo";
import { Stack } from "@mui/material";

// const TOP_NAV_HEIGHT: number = 64;

const LayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.background,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  height: "100%",
}));

interface LayoutProps {
  children: ReactNode;
}

export const Layout: FC<LayoutProps> = (props) => {
  const { children } = props;

  return (
    <LayoutRoot>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          flex: "1 1 auto",
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            py: {
              xs: "60px",
              md: "120px",
            },
          }}
        >
          <Stack
            alignItems="center"
            direction="column"
            justifyContent="center"
            spacing={2}
            sx={{ p: 3 }}
          >
            <Logo />
          </Stack>
          {children}
        </Container>
      </Box>
    </LayoutRoot>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};
