import type { FC } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export const HomeHero: FC = () => {

  return (
    <Box
      sx={{
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        pt: '120px'
      }}
    >
      <Container maxWidth="lg">
        <Box maxWidth="sm">
          <Typography
            variant="h1"
            sx={{ mb: 2 }}
          >
            Let us worry about&nbsp;
            <Typography
              component="span"
              color="primary.main"
              variant="inherit"
            >
              your agents staying on task.
            </Typography>
            You focus on your business.
          </Typography>
          <Typography
            color="text.secondary"
            sx={{
              fontSize: 20,
              fontWeight: 500
            }}
          >
            LEVEE montitors your agents' prompts and long term memory vector stores to ensure discipline in your agents.
          </Typography>
        </Box>
        <Box
          sx={{
            pt: '120px',
            position: 'relative'
          }}
        >
          <Box
            sx={{
              overflow: 'hidden',
              width: '90%',
              fontSize: 0,
              mt: -2, // hack to cut the bottom box shadow
              mx: -2,
              pt: 2,
              px: 2,
              '& img': {
                borderTopLeftRadius: (theme) => theme.shape.borderRadius * 2.5,
                borderTopRightRadius: (theme) => theme.shape.borderRadius * 2.5,
                boxShadow: 16,
                width: '100%'
              }
            }}
          >
            <img
              // src={
              //   theme.palette.mode === 'dark'
              //     ? '/assets/home-thumbnail-dark.png'
              //     : '/assets/home-thumbnail-light.png'
              // }
              src={'/assets/first-pass-home.png'}
            />
          </Box>
          <Box
            sx={{
              maxHeight: '100%',
              maxWidth: '100%',
              overflow: 'hidden',
              position: 'absolute',
              right: 0,
              top: 40,
              '& > div': {
                height: 460,
                width: 560
              }
            }}
          >
            {/* <HomeCodeSamples /> */}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};