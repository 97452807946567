export const paths = {
  index: '/',
  checkout: '/checkout',
  contact: '/contact',
  pricing: '/pricing',
  auth: {
    jwt: {
      login: '/auth/login',
      register: '/auth/register'
    },
    firebase: {
      login: '/auth/firebase/login/SOMEPATHTHATSHOULDNTBEFOUND',
      register: '/auth/firebase/register/SOMEPATHTHATSHOULDNTBEFOUND'
    },
  },
  dashboard: {
    index: '/app',
    account: '/app/account',
    chat: '/dashboard/chat',
    vectorStores: {
      index: '/app/vector-stores',
      details: '/app/vector-stores/:vectorStoreId',
      create: '/app/vector-stores/create',
      edit: '/app/vector-stores/:vector-stores/edit',
    },
    comparisonRuns: {
      index: '/app/comparison-runs',
      details: '/app/comparison-runs/:comparisonRunId',
      create: '/app/comparison-runs/create',
    },
    customComparisonTexts: {
      index: '/app/comparison-texts',
      details: '/app/comparison-texts/:comparisonTextId',
    },
    comparisonResults: {
      index: '/app/comparison-result/:vector_id',
    },
    promptApi: {
      index: '/app/prompt-api',
    },
    alerts: {
      index: '/app/alerts'
    }
  },
  marketing: {
    index: '/m',
  },
  notAuthorized: '/401',
  notFound: '/404',
  serverError: '/500'
};
