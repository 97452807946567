import type { FC } from "react";
import { Box } from "@mui/system";

export const Logo: FC = () => {


  return (
      <Box sx={{ maxWidth: 150 }}>
        <img src="/logo_transparent_background.png" style={{ width: "100%" }} />
      </Box>
  );
};