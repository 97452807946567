import type { FC } from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { LogoAccenture } from 'src/components/logos/logo-accenture';
import { LogoAtt } from 'src/components/logos/logo-att';
import { LogoAws } from 'src/components/logos/logo-aws';
import { LogoBolt } from 'src/components/logos/logo-bolt';
import { LogoSamsung } from 'src/components/logos/logo-samsung';
import { LogoVisma } from 'src/components/logos/logo-visma';


export const HomeReviews: FC = () => (
  <div>
    <Container maxWidth="lg">
      <Stack
        justifyContent="center"
        spacing={3}
        sx={{ py: 3 }}
      >
        <Typography
          align="center"
          color="text.secondary"
          variant="body2"
        >
          Used by companies like:
        </Typography>
        <Stack
          alignItems="center"
          direction="row"
          flexWrap="wrap"
          gap={4}
          justifyContent="center"
          sx={{
            color: 'action.active',
            '& > *': {
              flex: '0 0 auto'
            }
          }}
        >
          {/* <LogoSamsung /> */}
          {/* <LogoVisma />
          <LogoBolt />
          <LogoAws />
          <LogoAccenture />
          <LogoAtt /> */}
        </Stack>
      </Stack>
       <Stack
        spacing={8}
        sx={{ py: '20px' }}
      >
      </Stack> 
    </Container>
  </div>
);
