import { lazy, Suspense } from "react";
import type { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";

import { Layout as DashboardLayout } from "src/layouts/dashboard";

const IndexPage = lazy(() => import("src/pages/dashboard/index"));
const VectorStoreList = lazy(
  () => import("src/pages/dashboard/vectorStores/list")
);
const VectorStoreDetailPage = lazy(
  () => import("src/pages/dashboard/vectorStores/detail")
);
const VectorStoreEditPage = lazy(
  () => import("src/pages/dashboard/vectorStores/edit")
);
const VectorStoreCreatePage = lazy(
  () => import("src/pages/dashboard/vectorStores/create")
);
const ComparisonRunList = lazy(
  () => import("src/pages/dashboard/comparisonRuns/list")
);
const ComparisonRunDetailPage = lazy(
  () => import("src/pages/dashboard/comparisonRuns/detail")
);
const ComparisonTextIndexPage = lazy(
  () => import("src/pages/dashboard/comparisonTexts/index")
);
const ComparisonTextDetailPage = lazy(() => import("src/pages/dashboard/comparisonTexts/detail"));
const AlertsPage = lazy(
  () => import("src/pages/dashboard/alerts/index")
);
const ComparisonResultIndexPage = lazy(
  () => import("src/pages/dashboard/comparisonResults/detail")
);
const PromptApi = lazy(() => import("src/pages/dashboard/promptApi/index"));
const AccountPage = lazy(() => import("src/pages/dashboard/account"));

export const dashboardRoutes: RouteObject[] = [
  {
    path: "/app", // Change this to the desired base path
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
      {
        path: "vector-stores",
        children: [
          {
            index: true,
            element: <VectorStoreList />,
          },
          {
            path: ":vectorStoreId",
            element: <VectorStoreDetailPage />,
          },
          {
            path: ":vectorStoreId/edit",
            element: <VectorStoreEditPage />,
          },
          {
            path: "create",
            element: <VectorStoreCreatePage />,
          },
        ],
      },
      {
        path: "comparison-runs",
        children: [
          {
            index: true,
            element: <ComparisonRunList />,
          },
          {
            path: ":comparisonRunId",
            element: <ComparisonRunDetailPage />,
          },
        ],
      },
      {
        path: "comparison-texts",
        children: [
          {
            index: true,
            element: <ComparisonTextIndexPage />,
          },
        ],
      },
      {
        path: "comparison-texts/:comparisonTextId",
        children: [
          {
            index: true,
            element: <ComparisonTextDetailPage />,
          },
        ],
      },
      {
        path: "comparison-result/:vector_id",
        children: [
          {
            index: true,
            element: <ComparisonResultIndexPage />,
          },
        ],
      },
      {
        path: "alerts",
        children: [
          {
            index: true,
            element: <AlertsPage />,
          },
        ],
      },
      {
        path: "prompt-api",
        children: [
          {
            index: true,
            element: <PromptApi />,
          },
        ],
      },
      {
        path: "account",
        element: <AccountPage />,
      },
    ],
  },
];
