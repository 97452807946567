import type { ReactNode } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import SvgIcon from "@mui/material/SvgIcon";

import HomeSmileIcon from "src/icons/untitled-ui/duocolor/home-smile";
import Settings01Icon from "@untitled-ui/icons-react/build/esm/Settings01";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import ReadMoreOutlinedIcon from "@mui/icons-material/ReadMoreOutlined";
import ApiIcon from '@mui/icons-material/Api';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import CommitIcon from '@mui/icons-material/Commit';
import { tokens } from "src/locales/tokens";
import { paths } from "src/paths";

export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const useSections = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      {
        items: [
          {
            title: t(tokens.nav.overview),
            path: paths.dashboard.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.vectorStores),
            path: paths.dashboard.vectorStores.index,
            icon: (
              <SvgIcon fontSize="small">
                <StorageOutlinedIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.comparisonRuns),
            path: paths.dashboard.comparisonRuns.index,
            icon: (
              <SvgIcon fontSize="small">
                <CommitIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.alerts),
            path: paths.dashboard.alerts.index,
            icon: (
              <SvgIcon fontSize="small">
                <CrisisAlertIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.customComparisonTexts),
            path: paths.dashboard.customComparisonTexts.index,
            icon: (
              <SvgIcon fontSize="small">
                <ReadMoreOutlinedIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.promptApi),
            path: paths.dashboard.promptApi.index,
            icon: (
              <SvgIcon fontSize="small">
                <ApiIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.account),
            path: paths.dashboard.account,
            icon: (
              <SvgIcon fontSize="small">
                <Settings01Icon />
              </SvgIcon>
            ),
          },
        ],
      },
    ];
  }, [t]);
};
